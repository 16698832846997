import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: "https://student.janat-albelad.com/api",
    // apiUrl: "http://localhost:4300/api",
    // apiUrl: "http://5.5.5.10:4300/api",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
