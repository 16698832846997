<template>
  <div>
    <div>
      <v-img :src="require('../assets/turathHeader.png')" cover width="100vw" />
    </div>

    <v-container class="container sticky-header" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px" v-if="showDisplay == 2">
          <v-col cols="3" style="text-align: center">
            <div style="text-align: right">
              <v-alert border="left" dark dense text type="warning"> <h3>شروط قبول الصور الشخصية :</h3></v-alert>
              <h5>يفضل ان تكون بخلفية بيضاء وصورة رسمية</h5>
              <h5>بدون فلاتر</h5>
              <h5>لا تظهر كامل الجسم</h5>
              <h5>ولاتكون صورة جانبية</h5>
              <h5>بدون نظارات</h5>
              <h5>واذا خالفت الشروط اعلاه يهمل الطلب</h5>
              <h5>صيغة الصورة jpg</h5>
            </div>
            <v-avatar size="300" style="margin: 10px auto" v-if="isContinued || isGraduation">
              <img :src="photoPath" alt="الصورة الشخصية" v-if="photoPath" />
            </v-avatar>
            <div v-if="isContinued || isGraduation">
              <v-btn color="primary" rounded depressed :loading="isSelecting" @click="onButtonClick">
                <v-icon left> mdi-cloud-arrow-up </v-icon>
                اضافة صورة شخصية
              </v-btn>
              <input ref="uploader" class="d-none" type="file" accept=".jpg" @change="onFileChanged" />
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="9">
            <div style="height: 20px"></div>
            <v-alert border="left" style="text-align: center" dark color="primary"> <h3>منصة الخدمة السريعة لاصدار التأييدات والوثائق الخاصة بطلبة وخريجي جامعة التراث</h3> </v-alert>

            <!-- <v-alert border="left" dense text dark :color="setColor(student.statusId)">
              <h3>{{ student.statusId == 1 ? "الهوية قيد الطباعة" : student.statusId == 2 ? "تمت طباعة الهوية" : "تم رفض الطباعة" }}</h3>
            </v-alert> -->

            <div style="height: 20px"></div>

            <v-row>
              <v-col style="padding: 10px">
                <v-autocomplete v-model="documentType" :items="documents" item-text="name" outlined label="نوع المستند" return-object @change="setFields"></v-autocomplete>

                <div v-if="isGraduation">
                  <!-- <v-alert border="left" dark dense text type="info">
                    <h3>
                      سعر {{ documentType.name }}
                      {{
                        documentType.price.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                      }}
                    </h3>
                  </v-alert> -->
                  <v-text-field v-model="studentName" outlined label="اسم الطالب الرباعي"></v-text-field>
                  <v-text-field outlined v-model="sectionName" label="القسم المتخرج منه"></v-text-field>
                  <v-select :items="studyTypes" v-model="studentStudyType" item-text="name" outlined label="نوع الدراسة" return-object persistent-hint single-line></v-select>
                  <v-text-field v-model="graduationYear" outlined label="سنة التخرج"></v-text-field>
                  <v-text-field v-model="toDocument" outlined label="معنون الى"></v-text-field>
                  <v-text-field v-model="phoneNumber" outlined label="رقم الهاتف" type="number"></v-text-field>
                  <v-text-field v-model="studentDob" outlined label="تاريخ التولد"></v-text-field>
                  <v-text-field v-model="birthPlace" outlined label="محل الولادة"></v-text-field>
                  <v-checkbox v-model="isForeign" label="في حالة رغبة الطالب بمنحه وثيقة مصدقة من وزارة الخارجية"></v-checkbox>
                  <v-text-field v-model="numberOfCopy" outlined label="عدد النسخ" type="number"></v-text-field>

                  <div style="height: 10px"></div>
                  <v-textarea v-model="notes" outlined name="input-7-4" label="الملاحظات"></v-textarea>
                </div>
                <div v-if="isContinued">
                  <!-- <v-alert border="left" dark dense text type="info">
                    <h3>
                      سعر {{ documentType.name }}
                      {{
                        documentType.price.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })
                      }}
                    </h3>
                  </v-alert> -->
                  <v-text-field v-model="studentName" outlined label="اسم الطالب الرباعي"></v-text-field>
                  <v-text-field v-model="sectionName" outlined label="القسم "></v-text-field>
                  <v-select v-model="studentLevel" :items="studentLevels" item-text="name" outlined label="المرحلة" return-object persistent-hint single-line></v-select>
                  <v-text-field v-model="phoneNumber" outlined label="رقم الهاتف" type="number"></v-text-field>
                  <v-select v-model="studentStudyType" :items="studyTypes" item-text="name" outlined label="نوع الدراسة" return-object persistent-hint single-line></v-select>
                  <v-text-field v-model="toDocument" outlined label="معنون الى"></v-text-field>
                  <v-textarea v-model="notes" outlined name="input-7-4" label="الملاحظات"></v-textarea>
                </div>
              </v-col>
            </v-row>

            <div v-if="isContinued || isGraduation">
              <v-row>
                <v-col cols="6">
                  <div style="text-align: center">
                    <img :src="photoPathIdentityFace" width="300" height="300" alt="الصورة الشخصية" v-if="photoPath" />

                    <div>
                      <v-btn color="primary" depressed :loading="isSelectingIdentityFace" @click="onButtonClickIdentityFace">
                        <v-icon left> mdi-cloud-arrow-up </v-icon>
                        وجه هوية الاحوال المدنية / البطاقة الموحدة
                      </v-btn>
                      <input ref="uploadIdentityFace" class="d-none" type="file" accept=".jpg" @change="onFileChangedIdentityFace" />
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div style="text-align: center">
                    <img :src="photoPathIdentityBack" width="300" height="300" alt="الصورة الشخصية" v-if="photoPathIdentityBack" />

                    <div>
                      <v-btn color="primary" depressed :loading="isSelectingIdentityBack" @click="onButtonClickIdentityBack">
                        <v-icon left> mdi-cloud-arrow-up </v-icon>
                        ظهر هوية الاحوال المدنية / البطاقة الموحدة
                      </v-btn>
                      <input ref="uploadIdentityBack" class="d-none" type="file" accept=".jpg" @change="onFileChangedIdentityBack" />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div v-if="isContinued || isGraduation">
              <v-row>
                <v-col cols="6">
                  <div style="text-align: center">
                    <img :src="photoPathResidentFace" width="300" height="300" alt="الصورة الشخصية" v-if="photoPathResidentFace" />

                    <div>
                      <v-btn color="primary" depressed :loading="isSelectingResidentFace" @click="onButtonClickResidentFace">
                        <v-icon left> mdi-cloud-arrow-up </v-icon>
                        وجه بطاقة السكن
                      </v-btn>
                      <input ref="uploadResidentFace" class="d-none" type="file" accept=".jpg" @change="onFileChangedResidentFace" />
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div style="text-align: center">
                    <img :src="photoPathResidentBack" width="300" height="300" alt="الصورة الشخصية" v-if="photoPathResidentBack" />

                    <div>
                      <v-btn color="primary" depressed :loading="isSelectingResidentBack" @click="onButtonClickResidentBack">
                        <v-icon left> mdi-cloud-arrow-up </v-icon>
                        ظهر بطاقة السكن
                      </v-btn>
                      <input ref="uploadResidentBack" class="d-none" type="file" accept=".jpg" @change="onFileChangedResidentBack" />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-btn @click="saveStudentInformation" color="primary" style="margin: 10px" v-if="isContinued == true || isGraduation == true">
              <v-icon left> mdi-send </v-icon>
              ارسال
            </v-btn>
          </v-col>
        </v-row>

        <div v-if="showDisplay == 1" style="padding: 10px">
          <v-alert border="left" dark dense text type="warning"> <h3>شروط قبول الصور الشخصية :</h3></v-alert>
          <h5>يفضل ان تكون بخلفية بيضاء وصورة رسمية</h5>
          <h5>بدون فلاتر</h5>
          <h5>لا تظهر كامل الجسم</h5>
          <h5>ولاتكون صورة جانبية</h5>
          <h5>بدون نظارات</h5>
          <h5>واذا خالفت الشروط اعلاه يهمل الطلب</h5>
          <h5>صيغة الصورة jpg</h5>

          <div style="text-align: center">
            <v-avatar size="300" style="margin: 10px auto" v-if="isContinued || isGraduation">
              <img :src="photoPath" alt="الصورة الشخصية" v-if="photoPath" />
            </v-avatar>
          </div>

          <div style="text-align: center; margin-bottom: 10px" v-if="isContinued || isGraduation">
            <v-btn color="primary" v-if="showUpload" rounded depressed :loading="isSelecting" @click="onButtonClickMobile">
              <v-icon left> mdi-cloud-arrow-up </v-icon>
              اضافة صورة شخصية
            </v-btn>
            <input ref="uploader" class="d-none" type="file" accept="image/*" @change="onFileChanged" />

            <!-- <div>
              <v-btn color="primary" rounded depressed :loading="isSelecting" @click="onButtonClickMobile">
                <v-icon left> mdi-cloud-arrow-up </v-icon>
                اضافة صورة شخصية
              </v-btn>
              <input ref="uploader" class="d-none" type="file" accept=".jpg" @change="onFileChanged" />
            </div> -->
          </div>

          <v-autocomplete v-model="documentType" :items="documents" item-text="name" outlined label="نوع المستند" return-object @change="setFields"></v-autocomplete>

          <div v-if="isGraduation">
            <!-- <v-alert border="left" dark dense text type="info">
              <h3>
                سعر {{ documentType.name }}
                {{
                  documentType.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h3></v-alert
            > -->
            <v-text-field v-model="studentName" outlined label="اسم الطالب الرباعي"></v-text-field>
            <v-text-field outlined v-model="sectionName" label="القسم المتخرج منه"></v-text-field>
            <v-select :items="studyTypes" v-model="studentStudyType" item-text="name" outlined label="نوع الدراسة" return-object persistent-hint single-line></v-select>
            <v-text-field v-model="graduationYear" outlined label="سنة التخرج"></v-text-field>
            <v-text-field v-model="toDocument" outlined label="معنون الى"></v-text-field>
            <v-text-field v-model="phoneNumber" outlined label="رقم الهاتف" type="number"></v-text-field>
            <v-text-field v-model="studentDob" outlined label="تاريخ التولد"></v-text-field>
            <v-text-field v-model="birthPlace" outlined label="محل الولادة"></v-text-field>
            <v-checkbox v-model="isForeign" label="في حالة رغبة الطالب بمنحه وثيقة مصدقة من وزارة الخارجية"></v-checkbox>
            <v-text-field v-model="numberOfCopy" outlined label="عدد النسخ" type="number"></v-text-field>

            <div style="height: 10px"></div>
            <v-textarea v-model="notes" outlined name="input-7-4" label="الملاحظات"></v-textarea>
          </div>

          <div v-if="isContinued">
            <!-- <v-alert border="left" dark dense text type="info"> -->
            <!-- <h3>
                سعر {{ documentType.name }}
                {{
                  documentType.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h3> -->
            <!-- </v-alert> -->
            <v-text-field v-model="studentName" outlined label="اسم الطالب الرباعي"></v-text-field>
            <v-text-field v-model="sectionName" outlined label="القسم "></v-text-field>
            <v-select v-model="studentLevel" :items="studentLevels" item-text="name" outlined label="المرحلة" return-object persistent-hint single-line></v-select>
            <v-text-field v-model="phoneNumber" outlined label="رقم الهاتف" type="number"></v-text-field>
            <v-select v-model="studentStudyType" :items="studyTypes" item-text="name" outlined label="نوع الدراسة" return-object persistent-hint single-line></v-select>
            <v-text-field v-model="toDocument" outlined label="معنون الى"></v-text-field>
            <v-textarea v-model="notes" outlined name="input-7-4" label="الملاحظات"></v-textarea>
          </div>

          <div v-if="isContinued || isGraduation">
            <div style="text-align: center; margin-top: 10px">
              <img :src="photoPathIdentityFace" width="300" height="300" alt="الصورة الشخصية" v-if="photoPath" />

              <div>
                <v-btn color="primary" depressed :loading="isSelectingIdentityFace" @click="onButtonClickIdentityFace">
                  <v-icon left> mdi-cloud-arrow-up </v-icon>
                  وجه هوية الاحوال /البطاقة الموحدة
                </v-btn>
                <input ref="uploadIdentityFace" class="d-none" type="file" accept=".jpg" @change="onFileChangedIdentityFace" />
              </div>
            </div>

            <div style="text-align: center; margin-top: 10px">
              <img :src="photoPathIdentityBack" width="300" height="300" alt="الصورة الشخصية" v-if="photoPathIdentityBack" />

              <div>
                <v-btn color="primary" depressed :loading="isSelectingIdentityBack" @click="onButtonClickIdentityBack">
                  <v-icon left> mdi-cloud-arrow-up </v-icon>
                  ظهر هوية الاحوال /البطاقة الموحدة
                </v-btn>
                <input ref="uploadIdentityBack" class="d-none" type="file" accept=".jpg" @change="onFileChangedIdentityBack" />
              </div>
            </div>
          </div>

          <div v-if="isContinued || isGraduation">
            <div style="text-align: center; margin-top: 10px">
              <img :src="photoPathResidentFace" width="300" height="300" alt="الصورة الشخصية" v-if="photoPathResidentFace" />

              <div>
                <v-btn color="primary" depressed :loading="isSelectingResidentFace" @click="onButtonClickResidentFace">
                  <v-icon left> mdi-cloud-arrow-up </v-icon>
                  وجه بطاقة السكن
                </v-btn>
                <input ref="uploadResidentFace" class="d-none" type="file" accept=".jpg" @change="onFileChangedResidentFace" />
              </div>
            </div>

            <div style="text-align: center; margin-top: 10px">
              <img :src="photoPathResidentBack" width="300" height="300" alt="الصورة الشخصية" v-if="photoPathResidentBack" />

              <div>
                <v-btn color="primary" depressed :loading="isSelectingResidentBack" @click="onButtonClickResidentBack">
                  <v-icon left> mdi-cloud-arrow-up </v-icon>
                  ظهر بطاقة السكن
                </v-btn>
                <input ref="uploadResidentBack" class="d-none" type="file" accept=".jpg" @change="onFileChangedResidentBack" />
              </div>
            </div>
          </div>

          <v-btn @click="saveStudentInformation" color="primary" style="margin: 10px" v-if="isContinued || isGraduation">
            <v-icon left> mdi-send </v-icon>
            ارسال
          </v-btn>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    // collegeNumber: "1912100225",
    collegeNumber: "",
    studentEnglishName: "",

    studentName: "",
    sectionName: "",
    studentStudyType: "",
    graduationYear: "",
    toDocument: "",
    phoneNumber: "",
    studentDob: "",
    birthPlace: "",
    isForeign: "",
    numberOfCopy: "",
    documentPrice: "",
    notes: "",
    studentLevel: "",
    notes: "لايوجد",
    photoPath: "",
    update: false,
    studentLevels: [
      { name: "المرحلة الاولى", id: 1 },
      { name: "المرحلة الثانية", id: 2 },
      { name: "المرحلة الثالثة", id: 3 },
      { name: "المرحلة الرابعة", id: 4 },
      { name: "المرحلة الخامسة", id: 5 },
    ],
    gender: [
      { name: "ذكر", id: 1 },
      { name: "انثى", id: 2 },
    ],
    documents: [
      { name: "وثيقة تخرج بالدرجات عربي", id: 1, price: 40000 },
      { name: "وثيقة تخرج بالدرجات انكليزي", id: 1, price: 40000 },
      { name: "وثيقة تخرج بدون درجات عربي", id: 1, price: 40000 },
      { name: "وثيقة تخرج بدون درجات انكليزي", id: 1, price: 40000 },
      // { name: "تأييد تخرج", id: 2 },
      { name: "تأييد استمرارية", id: 2, price: 25000 },
      { name: "صحة الصدور", id: 2, price: 25000 },
    ],
    studyTypes: [
      { name: "صباحي", studyType: 1 },
      { name: "مسائي", studyType: 2 },
    ],
    student: null,
    selectedFile: null,
    isSelecting: false,

    isSelectingIdentityFace: false,
    isSelectingIdentityBack: false,
    isSelectingResidentFace: false,
    isSelectingResidentBack: false,
    photoPathIdentityFace: "",
    photoPathIdentityBack: "",
    photoPathResidentFace: "",
    photoPathResidentBack: "",
    photoPathSave: "",
    photoPathIdentityFaceSave: "",
    photoPathIdentityBackSave: "",
    photoPathResidentFaceSave: "",
    photoPathResidentBackSave: "",

    showUpload: true,
    overlay: false,
    showSend: true,
    documentType: null,
    isGraduation: false,
    isContinued: false,
  }),
  computed: {
    showDisplay() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 1;
        case "md":
          return 1;
        case "lg":
          return 2;
        case "xl":
          return 2;
      }
    },
  },
  created() {
    this.photoPath = require("./../assets/logo11.png");
    this.photoPathIdentityFace = require("./../assets/logo11.png");
    this.photoPathIdentityBack = require("./../assets/logo11.png");
    this.photoPathResidentFace = require("./../assets/logo11.png");
    this.photoPathResidentBack = require("./../assets/logo11.png");
  },

  methods: {
    setFields() {
      let self = this;
      if (self.documentType.id == 1) {
        self.isGraduation = true;
        self.isContinued = false;
      } else {
        self.isGraduation = false;
        self.isContinued = true;
      }
    },
    setColor(statusId) {
      if (statusId == 1) {
        return "warning";
      } else if (statusId == 2) {
        return "success";
      } else {
        return "error";
      }
    },
    getStudentByCollegeNumber() {
      let self = this;

      // 1912100225
      if (this.collegeNumber) {
        self.overlay = true;
        self.$http
          .get(`${self.$store.state.apiUrl}/studentIdentityFormCollegeNumber/${self.collegeNumber}`)
          .then((res) => {
            console.log(hasOwnProperty.call(res.data[0], "studentEmail"));
            self.overlay = false;
            if (res.data.length > 0) {
              self.student = res.data[0];
              if (self.student?.photoPath) {
                self.showUpload = !hasOwnProperty.call(self.student, "photoPath");
                self.showSend = !hasOwnProperty.call(self.student, "photoPath");
                self.student.studyType = { name: self.student.studyType == 1 ? "صباحي" : "مسائي", studyType: self.student.studyType };
              }
            } else {
              this.$toasted.error("لايوجد طالب بهذا الرقم الجامعي الرجاء التأكد من المعلومات");
            }
          })
          .catch((e) => {
            self.overlay = false;
            console.log(e);
            this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });
      } else {
        this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
      }
    },
    editStudentInformation(student) {
      let self = this;
      let studentData = {
        studentName: student.studentName,
        collegeNumber: student.collegeNumber,
        studentEnglishName: student.studentEnglishName,
        sectionName: student.sectionName,
        sectionEnglishName: student.sectionEnglishName,
        dob: student.dob,
        studentEmail: student.studentEmail,
        nationality: student.nationality,
        gender: student.gender,
        studyType: student.studyType.studyType,
        studentLevel: student.studentLevel,
        studentClass: student.studentClass,
        studentImage: fastService.studentImage,
        identityFace: fastService.identityFace,
        identityBack: fastService.identityBack,
        residentFace: fastService.residentFace,
        residentBack: fastService.residentBack,
        statusId: 1,
        photoPath: self.photoPath != "" ? self.photoPath : self.student.photoPath,
      };

      self.$http
        .put(`${self.$store.state.apiUrl}/studentIdentityForm/${student.idStudentIdentityForm}`, studentData)
        .then((res) => {
          this.$toasted.success("تم رفع البيانات");
          console.log(res.data);
          self.student = null;
          self.collegeNumber = "";
          self.overlay = false;
        })
        .catch((error) => {
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
        });
    },
    saveStudentInformation() {
      let self = this;
      self.overlay = true;

      if (
        self.studentName &&
        self.sectionName &&
        self.studentStudyType.studyType &&
        self.toDocument &&
        self.phoneNumber &&
        self.notes &&
        self.photoPathSave &&
        self.photoPathIdentityFaceSave &&
        self.photoPathIdentityBackSave &&
        self.photoPathResidentFaceSave &&
        self.photoPathResidentBackSave
      ) {
        let student = {
          studentName: self.studentName,
          sectionName: self.sectionName,
          studentStudyType: self.studentStudyType.studyType,
          graduationYear: self.graduationYear ? self.graduationYear : "لايوجد",
          toDocument: self.toDocument,
          phoneNumber: self.phoneNumber,
          studentDob: self.studentDob ? self.studentDob : "لايوجد",
          birthPlace: self.birthPlace ? self.birthPlace : "لايوجد",
          isForeign: self.isForeign ? true : false,
          studentLevel: self.studentLevel.id,
          numberOfCopy: self.numberOfCopy ? self.numberOfCopy : 1,
          documentPrice: self.documentType.price,
          documentType: self.documentType.name,
          studentImage: self.photoPathSave,
          identityFace: self.photoPathIdentityFaceSave,
          identityBack: self.photoPathIdentityBackSave,
          residentFace: self.photoPathResidentFaceSave,
          residentBack: self.photoPathResidentBackSave,
          notes: self.notes,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addFastService`, student)
          .then((res) => {
            this.$toasted.success("تم رفع البيانات");

            self.studentName = "";
            self.sectionName = "";
            self.studentStudyType.studyType = "";
            self.graduationYear = "";
            self.studentLevel = "";
            self.toDocument = "";
            self.phoneNumber = "";
            self.studentDob = "";
            self.birthPlace = "";
            self.isForeign = false;
            self.numberOfCopy = "";
            self.documentPrice = "";
            self.notes = "";
            self.documentType = null;
            self.photoPath = "";
            self.photoPathIdentityFace = "";
            self.photoPathIdentityBack = "";
            self.photoPathResidentFace = "";
            self.photoPathResidentBack = "";

            self.photoPathSave = "";
            self.photoPathIdentityFaceSave = "";
            self.photoPathIdentityBackSave = "";
            self.photoPathResidentFaceSave = "";
            self.photoPathResidentBackSave = "";

            self.isContinued = false;
            self.isGraduation = false;

            self.overlay = false;
          })
          .catch((error) => {
            self.overlay = false;
            this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });

        console.log(student);
      } else {
        this.$toasted.error("الرجاء رفع الصور مع المعلومات المطلوبة");
      }
    },
    onButtonClick() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploader.click();
    },

    onButtonClickResidentFace() {
      this.isSelectingResidentFace = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelectingResidentFace = false;
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploadResidentFace.click();
    },

    onButtonClickResidentBack() {
      this.isSelectingResidentBack = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelectingResidentBack = false;
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploadResidentBack.click();
    },

    onButtonClickIdentityFace() {
      this.isSelectingIdentityFace = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelectingIdentityFace = false;
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploadIdentityFace.click();
    },

    onButtonClickIdentityBack() {
      this.isSelectingIdentityBack = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelectingIdentityBack = false;
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploadIdentityBack.click();
    },

    onButtonClickMobile() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
          console.log("here");
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      let self = this;
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      this.isSelecting = false;

      self.overlay = true;
      let formData = new FormData();
      formData.append("attachment", self.selectedFile);
      self.$http
        .post(`${self.$store.state.apiUrl}/uploadStudentFast`, formData)
        .then((res) => {
          self.overlay = false;
          self.photoPath = `${self.$store.state.apiUrl}/${res.data.imagePath}`;
          self.photoPathSave = `${res.data.imagePath}`;

          this.$toasted.success("تم رفع الصورة");
          self.showUpload = false;
        })
        .catch((error) => {
          console.log(error);
          self.overlay = false;
          this.$toasted.error("حدث خطأ في رفع الصورة");
        });
    },

    onFileChangedIdentityFace(e) {
      let self = this;
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      this.isSelectingIdentityFace = false;

      self.overlay = true;
      let formData = new FormData();
      formData.append("attachment", self.selectedFile);
      self.$http
        .post(`${self.$store.state.apiUrl}/uploadStudentFast`, formData)
        .then((res) => {
          self.overlay = false;
          self.photoPathIdentityFace = `${self.$store.state.apiUrl}/${res.data.imagePath}`;
          self.photoPathIdentityFaceSave = `${res.data.imagePath}`;

          this.$toasted.success("تم رفع الصورة");
          self.showUpload = false;
        })
        .catch((error) => {
          console.log(error);
          self.overlay = false;
          this.$toasted.error("حدث خطأ في رفع الصورة");
        });
    },

    onFileChangedIdentityBack(e) {
      let self = this;
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      this.isSelectingIdentityBack = false;

      self.overlay = true;
      let formData = new FormData();
      formData.append("attachment", self.selectedFile);
      self.$http
        .post(`${self.$store.state.apiUrl}/uploadStudentFast`, formData)
        .then((res) => {
          self.overlay = false;
          self.photoPathIdentityBack = `${self.$store.state.apiUrl}/${res.data.imagePath}`;
          self.photoPathIdentityBackSave = `${res.data.imagePath}`;

          this.$toasted.success("تم رفع الصورة");
          self.showUpload = false;
        })
        .catch((error) => {
          console.log(error);
          self.overlay = false;
          this.$toasted.error("حدث خطأ في رفع الصورة");
        });
    },

    onFileChangedResidentFace(e) {
      let self = this;
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      this.isSelectingResidentFace = false;

      self.overlay = true;
      let formData = new FormData();
      formData.append("attachment", self.selectedFile);
      self.$http
        .post(`${self.$store.state.apiUrl}/uploadStudentFast`, formData)
        .then((res) => {
          self.overlay = false;
          self.photoPathResidentFace = `${self.$store.state.apiUrl}/${res.data.imagePath}`;
          self.photoPathResidentFaceSave = `${res.data.imagePath}`;

          this.$toasted.success("تم رفع الصورة");
          self.showUpload = false;
        })
        .catch((error) => {
          console.log(error);
          self.overlay = false;
          this.$toasted.error("حدث خطأ في رفع الصورة");
        });
    },

    onFileChangedResidentBack(e) {
      let self = this;
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      this.isSelectingResidentBack = false;

      self.overlay = true;
      let formData = new FormData();
      formData.append("attachment", self.selectedFile);
      self.$http
        .post(`${self.$store.state.apiUrl}/uploadStudentFast`, formData)
        .then((res) => {
          self.overlay = false;
          self.photoPathResidentBack = `${self.$store.state.apiUrl}/${res.data.imagePath}`;
          self.photoPathResidentBackSave = `${res.data.imagePath}`;

          this.$toasted.success("تم رفع الصورة");
          self.showUpload = false;
        })
        .catch((error) => {
          console.log(error);
          self.overlay = false;
          this.$toasted.error("حدث خطأ في رفع الصورة");
        });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-div {
  width: 300px;
  height: 300px;
  background: rgb(121, 121, 121);
  margin: 10px auto;
  border-radius: 150px;
}
</style>
